import React, { useState, useEffect, useRef } from 'react';
import Timer from './Timer';
import { BsFillPlayFill, BsPauseFill, BsStopFill } from 'react-icons/bs';

export default function CountdownTimer() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  const formatTime = (value) => (value < 10 ? `0${value}` : value.toString());

  useEffect(() => {
    let animationFrameId;

    if (isRunning) {
      const updateTime = () => {
        const now = Date.now();
        const timeLeft = remainingTime - (now - startTime);

        if (timeLeft <= 0) {
          resetTimer();
          return;
        }

        setRemainingTime(timeLeft);
        setStartTime(now);

        const ms = Math.floor((timeLeft % 1000) / 10);
        const sec = Math.floor((timeLeft / 1000) % 60);
        const min = Math.floor((timeLeft / (1000 * 60)) % 60);
        const hr = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);

        setMilliseconds(ms);
        setSeconds(sec);
        setMinutes(min);
        setHours(hr);

        animationFrameId = requestAnimationFrame(updateTime);
      };

      animationFrameId = requestAnimationFrame(updateTime);

      return () => cancelAnimationFrame(animationFrameId);
    } else {
      cancelAnimationFrame(animationFrameId);
    }
  }, [isRunning, startTime, remainingTime]);

  const startTimer = () => {
    if (hours || minutes || seconds || milliseconds) {
      setRemainingTime(
        hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds * 10
      );
      setStartTime(Date.now());
      setIsRunning(true);
    } else {
      window.alert('Add Time.');
    }
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const stopTimer = () => {
    resetTimer();
  };

  const resetTimer = () => {
    setIsRunning(false);
    setStartTime(0);
    setRemainingTime(0);
    setMilliseconds(0);
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  };

  const changeSeconds = (e) => {
    setSeconds(parseInt(e.target.value) || 0);
  };

  const changeMinutes = (e) => {
    setMinutes(parseInt(e.target.value) || 0);
  };

  const changeHours = (e) => {
    setHours(parseInt(e.target.value) || 0);
  };

  return (
    <div>
      <Timer
        milliseconds={formatTime(milliseconds)}
        seconds={formatTime(seconds)}
        minutes={formatTime(minutes)}
        hours={formatTime(hours)}
        changeSeconds={changeSeconds}
        changeMinutes={changeMinutes}
        changeHours={changeHours}
      />
      <br />
      {!isRunning && (
        <button className='btn btn-accept btn-lg' onClick={startTimer}>
          <BsFillPlayFill />
        </button>
      )}
      {isRunning && (
        <button className='btn btn-warning btn-lg' onClick={pauseTimer}>
          <BsPauseFill />
        </button>
      )}
      <button className='btn btn-danger btn-lg' onClick={stopTimer}>
        <BsStopFill />
      </button>
    </div>
  );
}
