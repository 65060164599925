import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import BackgroundMain from './background-black.png';
import BackgroundSub from './logo.png';
import BackgroundSub2 from './sub-logo.png';
import CountdownTimer from './components/CountdownTimer';

const App = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundMain})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        position: 'relative',
      }}
    >
      <CountdownTimer />
      <img
        src={BackgroundSub}
        alt='Overlay'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-75%, -80%)',
          maxWidth: '60%',
          maxHeight: '60%',
          objectFit: 'contain',
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default App;
