import React from 'react';
import styled from 'styled-components';

const TimerWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  position: absolute;
  top: 40%;
  bottom: 0;
  margin: auto 0;

  input {
    width: 300px;
    margin-right: 1rem;
    outline: none;
    border: none;
    font-size: 4.5rem;
    font-weight: 600;
    text-align: center;
    padding: 0rem 0.5rem;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    font-size: 200px;
    @media (min-width: 3840px) {
      width: 900px;
      font-size: 600px;
    }
    @media (min-width: 2560px) and (max-width: 3700px) {
      width: 450px;
      font-size: 300px;
    }
  }
  .space-house {
    font-size: 100px;
    color: #ffffff;
    @media (min-width: 3840px) {
      font-size: 250px;
    }
  }
`;

export default function Timer({
  milliseconds,
  seconds,
  minutes,
  hours,
  changeSeconds,
  changeMinutes,
  changeHours,
}) {
  return (
    <TimerWrapper>
      <div className='d-flex flex-column'>
        <input value={hours} onChange={changeHours} />
      </div>
      <div className='space-house'>:</div>
      <div className='d-flex flex-column'>
        <input value={minutes} onChange={changeMinutes} />
      </div>
      <div className='space-house'>:</div>
      <div className='d-flex flex-column'>
        <input value={seconds} onChange={changeSeconds} />
      </div>{' '}
    </TimerWrapper>
  );
}
